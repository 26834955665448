import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Bar } from 'react-chartjs-2';

import InputMask from 'react-input-mask';

import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Polygon,
  LoadScriptProps,
} from '@react-google-maps/api';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareIcon from '@mui/icons-material/IosShare';

import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { Loading } from '../../components/Loading';
import { SearchField } from '../../components/SearchField';
import { ImageField } from '../../components/ImageField';
import {
  formatCurrency,
  handleErrorMessage,
  serializePayload,
  initField,
  SUPORTED_DOC_FIELD_TYPES,
  SUPORTED_IMAGE_FIELD_TYPES,
  computeKML,
  downloadFile,
  SIMULATE_ACCOUNTANT_KEY,
} from '../../utils';
import {
  AccountantPayload,
  AccountantFormType,
  Accountant,
  SelectOption,
  SelectFieldType,
  HistoryChartData,
  FileFieldType,
  Address,
} from '../../types';
import {
  useFinancialInstitution,
  useInsuranceCompany,
  useAccountant,
  useCrop,
  useProduct,
  useProductHistory,
  useAuth,
  useFinancialAgency,
  useCustomer,
  useCore,
} from '../../contexts/contexts';

import { Form } from './styled';

import { initForm } from './utils';
import {
  BrazilCoordinates,
  convertToDecimalCoordinate,
  getBoundsZoomLevel,
} from '../../utils/googleMaps';
import { useUserByCpf } from '../../queries/useUserByCpf';
import { CircularProgress } from '@mui/material';
import { AlignVerticalCenter } from '@mui/icons-material';
import { useSessionAgency } from '../../queries/useSessionAgency';

const libraries = ['drawing', 'geometry'] as LoadScriptProps['libraries'];

const FORMAT_CHARS = {
  '9': '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9]',
  V: '[SN]',
  H: '[WE]',
};

export const AccountantForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false); // eslint-disable-next-line
  const [accountant, setAccountant] = useState<Accountant | undefined>();
  const { setAccountants, accountantApi } = useAccountant();
  const { id } = useParams();

  const { user } = useAuth();

  const {
    getProduct,
    getProductOptionsWithoutDisabled,
    productOptionsWithoutDisabled,
    product,
    subProductsOptions,
    getSubProductsOptions,
    subProduct,
    setSubProduct,
    getSubProductById,
  } = useProduct();
  const {
    setSubProductHistory,
    getProductHistoryByProductId,
    getSubProductHistoriesBySubProductId,
    subProductHistory,
  } = useProductHistory();

  const { coreApi } = useCore();

  const { getCustomerOptions } = useCustomer(); // TODO: Enable clienteId customersOptions
  const { insuranceCompanyApi } = useInsuranceCompany();
  const { financialInstitutionApi } = useFinancialInstitution();
  const { financialAgencyApi } = useFinancialAgency();
  const { getCropsOptions, cropsOptions } = useCrop();

  const [ComprovanteResidencia, setComprovanteResidencia] = useState(
    initField<FileFieldType>({
      name: 'ComprovanteResidencia',
      value: null,
      label: 'Comprovante de Residencia',
    })
  );

  const [ComprovanteKML, setComprovanteKML] = useState(
    initField<FileFieldType>({
      name: 'ComprovanteKML',
      value: null,
      label: 'Importar KML',
      required: true,
    })
  );

  const [ComprovanteCNH, setComprovanteCNH] = useState(
    initField<FileFieldType>({
      name: 'ComprovanteCNH',
      value: null,
      label: 'Comprovante CNH',
    })
  );

  const [financialInstitutionsOptions, setFinancialInstitutionsOptions] =
    useState<Array<SelectOption>>();

  const [financialAgenciesOptions, setFinancialAgenciesOptions] =
    useState<Array<SelectOption>>();

  const [insuranceCompaniesOptions, setInsuranceCompaniesOptions] =
    useState<Array<SelectOption>>();

  const [fetchingAccountant, setFetchingAccountant] = useState<boolean>(
    !isCreation
  );
  const [fetchingError, setFetchingError] = useState<string>();
  const [form, setForm] = useState<AccountantFormType>(
    initForm({}) as AccountantFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof AccountantFormType];
    auxForm[e.target.name as keyof AccountantFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = {
        ...serializePayload<AccountantFormType, AccountantPayload>(form),
        ValorDaCobertura: ValorDaCobertura.toString(),
        ImportanciaSeguradaTotal: ImportanciaSegurada.toString(),
        Premio: Premio.toString(),
        ComprovanteResidencia: ComprovanteResidencia.value,
        ComprovanteKML: ComprovanteKML.value,
        ComprovanteCNH: ComprovanteCNH.value,
      } as AccountantPayload;
      console.info(payload);
      if (payload?.CotadorId ?? false) {
        await accountantApi.editAccountant(payload);
      } else {
        await accountantApi.addAccountant(payload);
      }
      setAccountants(undefined);
      setSubProduct(undefined);
      navigate('/accountants');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getAccountant = async () => {
    if (id ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingAccountant(true);
        const data: Accountant = await accountantApi.getAccountantById(
          id as string
        );
        setForm(initForm({ data }) as AccountantFormType);
        setAccountant(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingAccountant(false);
      }
    }
  };

  const getFinancialInstitutionsOptions = async () => {
    try {
      setLoading(true);
      const data = await financialInstitutionApi.getFinancialInstitutions();
      setFinancialInstitutionsOptions(
        data.map(
          (financialInstitution) =>
          ({
            label: financialInstitution.nome,
            value: financialInstitution.instituicaoFinanceiraId,
          } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getFinancialAgenciesOptions = async () => {
    try {
      setLoading(true);
      const data = await financialAgencyApi.getFinancialAgencies();
      setFinancialAgenciesOptions(
        data.map(
          (financialAgency) =>
          ({
            label: financialAgency.nome,
            value: financialAgency.agenciaId,
          } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceCompaniesOptions = async () => {
    try {
      setLoading(true);
      const data = await insuranceCompanyApi.getInsuranceCompanies();
      setInsuranceCompaniesOptions(
        data.map(
          (insuranceCompanies) =>
          ({
            label: insuranceCompanies.nome,
            value: insuranceCompanies.seguradoraId,
          } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccountant();
    getInsuranceCompaniesOptions();
    getFinancialInstitutionsOptions();
    getFinancialAgenciesOptions();
    getCropsOptions();
    getProductOptionsWithoutDisabled();
    getCustomerOptions();
    setSubProduct(undefined);
  }, []);

  useEffect(() => {
    if (
      (financialInstitutionsOptions?.length ?? -1) > 0 &&
      (accountant ?? false)
    ) {
      const financialInstitution = financialInstitutionsOptions?.filter(
        (option) => {
          return option?.value === accountant?.instituicaoFinanceiraId;
        }
      );
      if ((financialInstitution?.length ?? -1) > 0) {
        setForm({
          ...form,
          InstituicaoFinanceiraId: {
            ...form.InstituicaoFinanceiraId,
            value: (financialInstitution ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [financialInstitutionsOptions, accountant]);

  useEffect(() => {
    if (
      (insuranceCompaniesOptions?.length ?? -1) > 0 &&
      (accountant ?? false)
    ) {
      const insuranceCompany = insuranceCompaniesOptions?.filter((option) => {
        return option?.value === accountant?.cotadorId;
      });
      if ((insuranceCompany?.length ?? -1) > 0) {
        setForm({
          ...form,
          SeguradoraId: {
            ...form.SeguradoraId,
            value: (insuranceCompany ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [insuranceCompaniesOptions, accountant]);

  useEffect(() => {
    if (product ?? false) {
      getSubProductsOptions();
      setForm({
        ...form,
        NivelDeCobertura: {
          ...form.NivelDeCobertura,
          value: product?.nivelDeCobertura,
        },
      });
    }
  }, [product]);

  useEffect(() => {
    if (subProduct?.subProdutoId ?? false) {
      getSubProductHistoriesBySubProductId(subProduct?.subProdutoId ?? '');
      setForm({
        ...form,
        SubProductId: {
          ...form.SubProductId,
          value: {
            value: subProduct?.subProdutoId ?? '',
            label: `${subProduct.estado} - ${subProduct.cidade}`,
          } as SelectOption,
        },
        EstadoId: { ...form.EstadoId, value: subProduct.estadoId.toString() },
        CidadeId: { ...form.CidadeId, value: subProduct.cidadeId.toString() },
        Taxa: { ...form.Taxa, value: subProduct.taxa },
      });
    } else {
      setForm({
        ...form,
        SubProductId: {
          ...form.SubProductId,
          value: null,
        },
        EstadoId: { ...form.EstadoId, value: '' },
        CidadeId: { ...form.CidadeId, value: '' },
        Taxa: { ...form.Taxa, value: '0' },
      });
    }
  }, [subProduct]);

  const selectHandleChange = ({
    value,
    field,
  }: {
    value: SelectOption;
    field: SelectFieldType;
  }) => {
    setError(undefined);
    setForm({
      ...form,
      [field.name]: { ...field, value: value },
    });
  };

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  const ValorDaCobertura =
    parseFloat(form.ImportanciaSeguradaPorHA.value ?? '0') *
    parseFloat(form.Area.value ?? '0');
  const ImportanciaSegurada =
    ValorDaCobertura * (parseFloat(form.NivelDeCobertura.value ?? '0') / 100);
  const Premio =
    ImportanciaSegurada * (parseFloat(form.Taxa.value ?? '0') / 100);

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [, setLoadingMap] = useState<boolean>(true);
  const [goToCoordinate, setGoToCoordinate] = useState<{
    lat: string;
    lng: string;
  }>({
    lat: '',
    lng: '',
  });

  const [drawedPolygons, setDrawedPolygons] = useState<
    Array<google.maps.Polygon>
  >([]);

  const [drawingControl, setDrawingControl] = useState<boolean>(true);

  const [computedArea, setComputedArea] = useState<string>('0,00');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAF8QP6R3rJFZLZJcEsee3B3a1wz6Pul8E',
    libraries,
  });

  const onLoad = React.useCallback(function callback(
    map: google.maps.Map | null
  ) {
    setMap(map);
    setLoadingMap(false);
  },
    []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const computeHa = (polygon: google.maps.Polygon) => {
    return (
      (google.maps.geometry.spherical.computeArea(polygon.getPath()) ?? 0) /
      10000
    );
  };

  const fitBounds = (polygons: Array<google.maps.Polygon>) => {
    const bounds = new google.maps.LatLngBounds();
    polygons.map((polygon) => {
      polygon.getPaths().forEach((path) => {
        path.forEach((latlng) => {
          bounds.extend(latlng);
        });
      });
    });
    map?.fitBounds(bounds);
    const zoom = getBoundsZoomLevel(bounds, {
      width: window.innerWidth / 2,
      height: 300,
    });
    map?.setZoom(zoom);
  };

  useEffect(() => {
    if (drawedPolygons.length > 0) {
      fitBounds(drawedPolygons);
    }
  }, [drawedPolygons]);

  const loadKML = async (field: File | null) => {
    let kml = (await field?.text()) ?? null;
    if (kml) {
      let isCoordinate = false;
      const polygons: string[][] = [];
      let coordinates: string[] = [];
      kml = kml.replace('\t', '  ');
      kml.split('\n').forEach((item) => {
        if (item.indexOf('</coordinates>') > 0) {
          isCoordinate = false;
          polygons.push(coordinates);
        }
        if (isCoordinate) {
          coordinates = coordinates.concat(item.trim().split(' ') ?? []);
        }
        if (item.indexOf('<coordinates>') > 0) {
          isCoordinate = true;
          coordinates = [];
        }
      });

      try {
        if ((polygons.length ?? 0) > 0) {
          setComputedArea('0,00');
          setDrawedPolygons([]);
          const items = polygons.map((item: string[]) => {
            const polygon = new google.maps.Polygon({
              paths: item.map((coordinate) => {
                const [lng, lat] = coordinate.split(',');
                return { lat: parseFloat(lat), lng: parseFloat(lng) };
              }),
              editable: true,
              visible: true,
              fillColor: theme.palette.primary.dark,
              fillOpacity: 0.8,
            });
            polygon.setMap(map);
            return polygon;
          });
          setDrawedPolygons(items);
        }
      } catch (e: unknown | Error) {
        setError(`${e}`);
      }
    }
  };

  useEffect(() => {
    if (
      isCreation &&
      financialInstitutionsOptions?.length &&
      user.role === 'instituicaofinanceira'
    ) {
      setForm({
        ...form,
        InstituicaoFinanceiraId: {
          ...form.InstituicaoFinanceiraId,
          value: { value: user.usuarioId, label: user.nome } as SelectOption,
        },
      });
    }
  }, [user, financialAgenciesOptions]);


  const sessionAgencyQuery = useSessionAgency({ enabled: user.role === 'agencia' })

  useEffect(() => {

    if (sessionAgencyQuery.data && (financialInstitutionsOptions?.length ?? 0) > 0) {
      if ((sessionAgencyQuery.data?.instituicaoFinanceiraId?.length ?? 0) > 0) {
        let auxForm = { ...form }
        auxForm.InstituicaoFinanceiraId = {
          ...auxForm.InstituicaoFinanceiraId, disabled: true, value: {
            label: financialInstitutionsOptions?.find((item) => item?.value === sessionAgencyQuery.data.instituicaoFinanceiraId)?.label ?? '',
            value: sessionAgencyQuery.data.instituicaoFinanceiraId!
          }
        }
        setForm(auxForm)
      }
    }

  }, [sessionAgencyQuery.data, financialInstitutionsOptions])

  useEffect(() => {
    if (
      isCreation &&
      financialAgenciesOptions?.length &&
      user.role === 'agencia'
    ) {
      setForm({
        ...form,
        AgenciaId: {
          ...form.AgenciaId,
          disabled: true,
          value: { value: user.usuarioId, label: user.nome } as SelectOption,
        },
      });
    }
  }, [user, financialAgenciesOptions]);

  const [cpf, setCpf] = useState<string>();

  const userByCpfQuery = useUserByCpf({ cpf });

  useEffect(() => {
    if (userByCpfQuery.data) {
      const data = userByCpfQuery.data;
      let auxForm = { ...form };

      if (data.email?.length) {
        auxForm.EmailCliente = { ...auxForm.EmailCliente, value: data.email };
      }
      if (data.nome?.length) {
        auxForm.NomeCliente = { ...auxForm.NomeCliente, value: data.nome };
      }
      if (data.telefone?.length) {
        auxForm.TelefoneCliente = {
          ...auxForm.TelefoneCliente,
          value: data.telefone,
        };
      }
      if (data.cep?.length) {
        auxForm.CEPCliente = { ...auxForm.CEPCliente, value: data.cep };
      }
      if (data.uf?.length) {
        auxForm.UFCliente = { ...auxForm.UFCliente, value: data.uf };
      }
      if (data.cidade?.length) {
        auxForm.CidadeCliente = {
          ...auxForm.CidadeCliente,
          value: data.cidade,
        };
      }
      if (data.bairro?.length) {
        auxForm.BairroCliente = {
          ...auxForm.BairroCliente,
          value: data.bairro,
        };
      }
      if (data.endereco?.length) {
        auxForm.EnderecoCliente = {
          ...auxForm.EnderecoCliente,
          value: data.endereco,
        };
      }
      if (data.numeroEndereco?.length) {
        auxForm.NumeroEnderecoCliente = {
          ...auxForm.NumeroEnderecoCliente,
          value: data.numeroEndereco,
        };
      }
      if (data.complemento?.length) {
        auxForm.ComplementoCliente = {
          ...auxForm.ComplementoCliente,
          value: data.complemento,
        };
      }
      setForm(auxForm);
    }
  }, [userByCpfQuery.data]);

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && <ErrorMsg error={fetchingError} />}

        {fetchingAccountant && <Loading />}
        {!fetchingError && !fetchingAccountant && (
          <>
            <Typography variant="h5" component="h2" color="textSecondary">
              Cotação
            </Typography>
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                gap: theme.spacing(2),
                flexDirection: 'column',
              }}
            >
              <ErrorMsg error={error} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6} md={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: theme.spacing(2),
                      flexDirection: 'column',
                    }}
                  >
                    <SearchField
                      extraAttrs={{ value: form.ProdutoId.value }}
                      options={productOptionsWithoutDisabled}
                      label={form.ProdutoId.label}
                      required={form.ProdutoId.required ? true : false}
                      loading={loading}
                      handleChange={(value: SelectOption) => {
                        setError(undefined);
                        setForm({
                          ...form,
                          ProdutoId: { ...form.ProdutoId, value: value },
                          SubProductId: { ...form.SubProductId, value: null },
                        });
                        getProduct(value?.value ?? '');
                        getProductHistoryByProductId(value?.value ?? '');
                        setSubProductHistory(undefined);
                      }}
                    />
                    <SearchField
                      extraAttrs={{ value: form.SubProductId.value }}
                      options={subProductsOptions}
                      label={form.SubProductId.label}
                      required={form.SubProductId.required ? true : false}
                      loading={loading}
                      handleChange={(value: SelectOption) => {
                        if (value ?? false) {
                          getSubProductById(value?.value ?? '');
                        } else {
                          setSubProduct(undefined);
                        }
                      }}
                    />
                    {['admin', 'agencia', 'instituicaofinanceira', 'corretora'].includes(
                      user?.role
                    ) && (
                        <>
                          <SearchField
                            extraAttrs={{
                              value: form.InstituicaoFinanceiraId.value,
                            }}
                            options={financialInstitutionsOptions}
                            label={form.InstituicaoFinanceiraId.label}
                            required={!!form.InstituicaoFinanceiraId.required}
                            loading={loading}
                            disabled={form.InstituicaoFinanceiraId.disabled ?? false}
                            handleChange={(value: SelectOption) => {
                              setError(undefined);
                              setForm({
                                ...form,
                                InstituicaoFinanceiraId: {
                                  ...form.InstituicaoFinanceiraId,
                                  value: value,
                                },
                              });
                            }}
                          />
                          <SearchField
                            extraAttrs={{ value: form.AgenciaId.value }}
                            options={financialAgenciesOptions}
                            label={form.AgenciaId.label}
                            required={form.AgenciaId.required ? true : false}
                            disabled={form.AgenciaId.disabled}
                            loading={loading}
                            handleChange={(value: SelectOption) => {
                              setError(undefined);
                              setForm({
                                ...form,
                                AgenciaId: { ...form.AgenciaId, value: value },
                              });
                            }}
                          />
                        </>
                      )}
                    <SearchField
                      extraAttrs={{ value: form.CulturaId.value }}
                      options={cropsOptions}
                      label={form.CulturaId.label}
                      required={form.CulturaId.required ? true : false}
                      loading={loading}
                      handleChange={(value: SelectOption) => {
                        selectHandleChange({
                          value,
                          field: form.CulturaId,
                        });
                      }}
                    />
                    <TextField
                      field={form.ImportanciaSeguradaPorHA}
                      extraAttrs={extraAttrs}
                    />
                    <TextField field={form.Area} extraAttrs={extraAttrs} />
                    {/*  <SearchField
                      extraAttrs={{ value: form.ClienteId.value }}
                      options={customersOptions}
                      label={form.ClienteId.label}
                      required={form.ClienteId.required ? true : false}
                      loading={loading}
                      handleChange={(value: SelectOption) => {
                        selectHandleChange({ value, field: form.ClienteId });
                      }}
                    /> */}
                    <TextField
                      field={form.NomeBeneficiario}
                      extraAttrs={extraAttrs}
                    />
                    <TextField
                      field={form.CNPJBeneficiario}
                      extraAttrs={extraAttrs}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: theme.spacing(2),
                      flexDirection: 'column',
                    }}
                  >
                    <TextField
                      field={form.CPFCliente}
                      extraAttrs={{
                        ...extraAttrs,
                        disabled: userByCpfQuery.isLoading,
                        onBlur: (e: any) => {
                          if (e.target.value?.length) {
                            let value = e.target.value;
                            e.target.value = value.replace(
                              /^(\d{3})\.(\d{3})\.(\d{3})-(\d{2})$/,
                              '$1.$2.$3-$4'
                            );
                            setCpf(e.target.value.replace(/[^\d]/g, ''));
                          }
                          setCpf(e.target.value)
                        },
                      }}
                    />
                    {userByCpfQuery.isError && !userByCpfQuery.isLoading && (cpf?.length ?? 0) > 10 ? (
                      <Box sx={{ paddingBottom: 1, fontSize: 16, color: '#f87171' }}>Nenhum cadastro de cliente com esse CPF Encontrado</Box>
                    ) : null}
                    {userByCpfQuery.isLoading && (cpf?.length ?? 0) > 10 ? (
                      <Box sx={{ paddingBottom: 1, fontSize: 16, color: '#737373', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size={16} />
                        Carregando informações do client</Box>
                    ) : null}
                    <TextField
                      field={form.NomeFazendaCliente}
                      extraAttrs={extraAttrs}
                    />
                    <TextField
                      field={form.EmailCliente}
                      extraAttrs={extraAttrs}
                    />
                    <TextField
                      field={form.NomeCliente}
                      extraAttrs={{ ...extraAttrs }}
                    />
                    <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
                      <TextField
                        field={form.TelefoneCliente}
                        extraAttrs={extraAttrs}
                      />
                      <TextField
                        field={form.CEPCliente}
                        extraAttrs={{
                          ...extraAttrs,
                          onBlur: async () => {
                            const cep = form.CEPCliente.value.replace('-', '');
                            let address: Address | null = null;
                            if (cep.length === 8) {
                              try {
                                const data = await coreApi.getCepData(cep);
                                address = data;
                              } catch (e) {
                                console.info(e);
                              }
                            }
                            if (address ?? false) {
                              setForm({
                                ...form,
                                BairroCliente: {
                                  ...form.BairroCliente,
                                  value: address?.bairro ?? '',
                                },
                                CidadeCliente: {
                                  ...form.CidadeCliente,
                                  value: address?.localidade ?? '',
                                },
                                UFCliente: {
                                  ...form.UFCliente,
                                  value: address?.uf ?? '',
                                },
                                EnderecoCliente: {
                                  ...form.EnderecoCliente,
                                  value: address?.logradouro ?? '',
                                },
                                ComplementoCliente: {
                                  ...form.ComplementoCliente,
                                  value: address?.complemento ?? '',
                                },
                              });
                            }
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
                      <TextField
                        field={form.UFCliente}
                        extraAttrs={extraAttrs}
                      />
                      <TextField
                        field={form.CidadeCliente}
                        extraAttrs={extraAttrs}
                      />
                    </Box>
                    <TextField
                      field={form.BairroCliente}
                      extraAttrs={extraAttrs}
                    />
                    <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
                      <TextField
                        field={form.EnderecoCliente}
                        extraAttrs={extraAttrs}
                      />
                      <TextField
                        field={form.NumeroEnderecoCliente}
                        extraAttrs={extraAttrs}
                      />
                    </Box>
                    <TextField
                      field={form.ComplementoCliente}
                      extraAttrs={extraAttrs}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={12} lg={6} md={12}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FormControl
                      sx={{
                        width: '100%',
                        paddingRight: theme.spacing(2),
                      }}
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        Nível de Cobertura
                      </FormLabel>
                      <FormGroup>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: theme.spacing(3),
                          }}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            variant="body1"
                            fontWeight="bold"
                            fontSize={theme.spacing(5)}
                            color="primary"
                          >
                            {form.NivelDeCobertura.value}%
                          </Typography>
                          <Slider
                            aria-label="Nível de Cobertura"
                            defaultValue={0}
                            value={parseFloat(form.NivelDeCobertura.value)}
                            getAriaValueText={(value: number) => `${value}%`}
                            step={1}
                            min={0}
                            max={
                              product
                                ? product?.nivelDeCoberturaVariavel
                                  ? parseFloat(product?.nivelDeCobertura)
                                  : 100
                                : 100
                            }
                            marks={[
                              {
                                value: 0,
                                label: '0%',
                              },
                              {
                                value: product
                                  ? product?.nivelDeCoberturaVariavel
                                    ? parseFloat(product?.nivelDeCobertura)
                                    : 100
                                  : 100,
                                label: `${product
                                  ? product?.nivelDeCoberturaVariavel
                                    ? parseFloat(product?.nivelDeCobertura)
                                    : 100
                                  : 100
                                  }%`,
                              },
                            ]}
                            onChange={(
                              _: Event,
                              value: number | Array<number>
                            ) => {
                              setError(undefined);
                              const auxForm = { ...form };
                              auxForm.NivelDeCobertura = {
                                ...auxForm.NivelDeCobertura,
                                value: `${value.toString()}`,
                              };
                              setForm(auxForm);
                            }}
                            disabled={
                              product
                                ? product?.nivelDeCoberturaVariavel
                                  ? undefined
                                  : true
                                : true
                            }
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      </FormGroup>
                      <FormHelperText>
                        {product ? undefined : 'Selecione o Produto'}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={12}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize={theme.spacing(3)}
                      color="textSecondary"
                    >
                      Variáveis
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        gap: theme.spacing(3),
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          fontWeight="medium"
                        >
                          Importancia Segurada
                        </Typography>
                        <Typography
                          color="primary"
                          variant="body1"
                          fontWeight="bold"
                          fontSize={theme.spacing(3)}
                        >
                          R$
                          {formatCurrency(
                            ValorDaCobertura.toFixed(2).replace('.', ',')
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          fontWeight="medium"
                        >
                          Valor da Cobertura
                        </Typography>
                        <Typography
                          color="primary"
                          variant="body1"
                          fontWeight="bold"
                          fontSize={theme.spacing(3)}
                        >
                          R$
                          {formatCurrency(
                            ImportanciaSegurada.toFixed(2).replace('.', ',')
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          fontWeight="medium"
                        >
                          Prêmio
                        </Typography>
                        <Typography
                          color="primary"
                          variant="body1"
                          fontWeight="bold"
                          fontSize={theme.spacing(3)}
                        >
                          R$
                          {formatCurrency(Premio.toFixed(2).replace('.', ','))}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          fontWeight="medium"
                        >
                          Área Calculada
                        </Typography>
                        <Typography
                          color="primary"
                          variant="body1"
                          fontWeight="bold"
                          fontSize={theme.spacing(3)}
                        >
                          {parseFloat(computedArea) > 0
                            ? parseFloat(computedArea).toFixed(2)
                            : '0.00'}{' '}
                          ha
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={12}>
                  <Box>
                    {isLoaded ? (
                      <>
                        <GoogleMap
                          mapContainerStyle={{
                            width: '100%',
                            height: '300px',
                          }}
                          center={BrazilCoordinates}
                          zoom={4}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          options={{
                            streetViewControl: false,
                          }}
                          onClick={(event) => {
                            const bounds = new google.maps.LatLngBounds(
                              event.latLng
                            );
                            map?.fitBounds(bounds);
                          }}
                        >
                          {drawedPolygons.map((polygon, index) => (
                            <Polygon
                              key={index}
                              paths={polygon.getPaths()}
                              options={{
                                fillColor: theme.palette.primary.dark,
                                fillOpacity: 0.8,
                                clickable: drawingControl,
                                editable: drawingControl,
                                zIndex: 1,
                              }}
                            />
                          ))}
                          {drawingControl && (
                            <DrawingManager
                              onPolygonComplete={(
                                polygon: google.maps.Polygon
                              ) => {
                                setDrawedPolygons([...drawedPolygons, polygon]);
                                setError(undefined);
                                setComputedArea(
                                  (
                                    parseFloat(computedArea) +
                                    computeHa(polygon)
                                  ).toString()
                                );
                                polygon.setOptions({
                                  fillColor: theme.palette.primary.dark,
                                  fillOpacity: 0.8,
                                });
                              }}
                              drawingMode={
                                google.maps.drawing.OverlayType.POLYGON
                              }
                              options={{
                                drawingControl: drawingControl,
                                polygonOptions: {
                                  clickable: drawingControl,
                                  editable: drawingControl,
                                },
                                drawingControlOptions: {
                                  drawingModes: [
                                    google.maps.drawing.OverlayType.POLYGON,
                                  ],
                                },
                              }}
                            />
                          )}
                        </GoogleMap>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary">
                      Ir para
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <FormGroup style={{ display: 'flex' }}>
                        <FormLabel>Latitude</FormLabel>
                        <InputMask
                          style={{
                            height: 40,
                            width: 180,
                            padding: theme.spacing(1),
                          }}
                          mask={`99°99'99.9999"S`}
                          maskChar=" "
                          formatChars={FORMAT_CHARS}
                          placeholder={`90°60'59.9999"S`}
                          onChange={(e) => {
                            setGoToCoordinate({
                              lat: e.target.value,
                              lng: goToCoordinate.lng,
                            });
                          }}
                        />
                      </FormGroup>
                      <FormGroup style={{ display: 'flex' }}>
                        <FormLabel>Longitude</FormLabel>
                        <InputMask
                          style={{
                            height: 40,
                            width: 180,
                            padding: theme.spacing(1),
                          }}
                          mask={`99°99'99.9999"W`}
                          formatChars={FORMAT_CHARS}
                          maskChar=" "
                          placeholder={`60°60'59.9999"W`}
                          onChange={(e) => {
                            setGoToCoordinate({
                              lat: goToCoordinate.lat,
                              lng: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                      <Box>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const coordinates = {
                              lat: convertToDecimalCoordinate(
                                goToCoordinate.lat
                              ),
                              lng: convertToDecimalCoordinate(
                                goToCoordinate.lng
                              ),
                            };

                            if (
                              coordinates.lat != null &&
                              coordinates.lng != null
                            ) {
                              const bounds =
                                new window.google.maps.LatLngBounds(
                                  coordinates as google.maps.LatLngLiteral
                                );
                              map?.fitBounds(bounds);
                              map?.setZoom(16);
                            }
                          }}
                          sx={{ color: 'white', marginTop: 3 }}
                        >
                          Confirmar
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Áreas desenhadas
                    </Typography>
                    {drawedPolygons.length === 0 && (
                      <Typography variant="caption" color="textSecondary">
                        Por favor, realize o desenho do KML no mapa
                      </Typography>
                    )}
                    {drawedPolygons.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: theme.spacing(1),
                            padding: theme.spacing(1),
                          }}
                        >
                          <Typography variant="body1" color="textSecondary">
                            Área {index + 1}
                          </Typography>
                          <Chip
                            label={`${computeHa(item).toFixed(2)} ha`}
                            color="primary"
                            variant="outlined"
                          />
                          <IconButton
                            disabled={!drawingControl}
                            onClick={() => {
                              setComputedArea(
                                (
                                  parseFloat(computedArea) - computeHa(item)
                                ).toString()
                              );
                              item.setMap(null);
                              setDrawedPolygons(
                                drawedPolygons.filter((_, i) => i !== index)
                              );
                            }}
                            sx={{ padding: theme.spacing(0.5) }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                    {drawedPolygons.length > 0 && (
                      <>
                        {drawingControl ? (
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              setDrawingControl(false);
                              drawedPolygons.map((polygon) => {
                                polygon.setOptions({
                                  editable: false,
                                });
                              });
                              setComputedArea(
                                drawedPolygons.reduce(
                                  (acc, item) =>
                                    (
                                      parseFloat(acc) + computeHa(item)
                                    ).toString(),
                                  '0'
                                )
                              );
                              const fileData = computeKML(drawedPolygons);
                              downloadFile('generated.kml', fileData);
                              const blob = new Blob([fileData], {
                                type: 'text/plain',
                              });
                              const file = new File([blob], 'generated.kml', {
                                type: 'text/plain',
                              });
                              setComprovanteKML({
                                ...ComprovanteKML,
                                value: file,
                              });
                            }}
                            sx={{ color: 'white' }}
                          >
                            Confirmar e Exportar KML
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              setDrawingControl(true);
                              drawedPolygons.map((polygon) => {
                                polygon.setOptions({
                                  editable: true,
                                });
                              });
                            }}
                          >
                            Editar KML {ComprovanteKML.value?.name ?? 'vazio'}
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isCreation && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    loading={loading}
                    disabled={error ? true : undefined}
                    onClick={() => {
                      const data = {
                        ...serializePayload<
                          AccountantFormType,
                          AccountantPayload
                        >(form),
                        ValorDaCobertura: ValorDaCobertura.toString(),
                        ImportanciaSeguradaTotal:
                          ImportanciaSegurada.toString(),
                        Premio: Premio.toString(),
                        SubProductHistory: subProductHistory,
                      } as AccountantPayload;
                      sessionStorage.setItem(
                        SIMULATE_ACCOUNTANT_KEY,
                        JSON.stringify(data)
                      );
                      window.open(`/accountant/simulate/`);
                    }}
                    endIcon={<IosShareIcon color="primary" />}
                  >
                    <Typography variant="button" color="primary">
                      Imprimir Orçamento
                    </Typography>
                  </Button>
                )}
              </Box>

              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  gap: theme.spacing(3),
                }}
              >
                <ImageField
                  setField={(field: FileFieldType) => {
                    setComprovanteResidencia(field);
                    setError(undefined);
                  }}
                  field={ComprovanteResidencia}
                  allowedFileTypes={SUPORTED_IMAGE_FIELD_TYPES.concat(
                    SUPORTED_DOC_FIELD_TYPES
                  )}
                />
                <ImageField
                  setField={(field: FileFieldType) => {
                    setComprovanteKML(field);
                    setError(undefined);
                    loadKML(field?.value);
                  }}
                  field={ComprovanteKML}
                  allowedFileTypes={['kml']}
                />
                <ImageField
                  setField={(field: FileFieldType) => {
                    setComprovanteCNH(field);
                    setError(undefined);
                  }}
                  field={ComprovanteCNH}
                  allowedFileTypes={['pdf']}
                />
              </Box>
              {subProductHistory && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: theme.spacing(3),
                    marginLeft: theme.spacing(10),
                    marginRight: theme.spacing(10),
                    boxSizing: 'border-box',
                  }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: (item) => {
                              return `%${item.raw}`;
                            },
                          },
                        },
                      },
                      scales: {
                        yAxes: {
                          ticks: {
                            callback: (tickValue) => {
                              return '%' + tickValue;
                            },
                          },
                        },
                      },
                    }}
                    data={subProductHistory as HistoryChartData}
                  />
                </div>
              )}

              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: theme.spacing(2),
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={loading}
                  disabled={error ? true : undefined}
                  endIcon={
                    <ArrowForwardIcon
                      sx={{ color: theme.palette.common.white }}
                    />
                  }
                >
                  <Typography variant="button" color="white">
                    {isCreation ? 'Enviar Proposta' : 'Salvar'}
                  </Typography>
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Grid>
    </Layout>
  );
};
